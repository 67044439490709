import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GetAvailableScreensResult } from '@models/screen';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  baseUrl = `${environment.baseUrl}/api/screens`;

  constructor(private http: HttpClient) {}

  getAvailableScreens(): Observable<string[]> {
    return this.http
      .get<GetAvailableScreensResult>(this.baseUrl)
      .pipe(map((resp) => resp?.availableScreens || []));
  }
}
