import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ScreenService } from '@services/screen.service';

export const screenAccessGuard: CanActivateFn = (route) => {
  const screenService = inject(ScreenService);
  const isScreenAvailable = screenService.checkScreenAvailable(
    route.data.screen,
  );

  return isScreenAvailable;
};
