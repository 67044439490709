import { Routes } from '@angular/router';
import { PrivateRoutes } from '@models/routes';
import { screenAccessGuard } from './guards/screen-access.guard';

export const mainRoutes: Routes = [
  {
    path: PrivateRoutes.personalCalendar,
    loadComponent: () =>
      import('@pages/personal-calendar/personal-calendar-page.component').then(
        (m) => m.PersonalCalendarPageComponent,
      ),
    loadChildren: () =>
      import('@pages/personal-calendar/personal-calendar-page.routes').then(
        (m) => m.personalCalendarPageRoutes,
      ),
    canActivate: [screenAccessGuard],
    data: { screen: PrivateRoutes.personalCalendar },
  },
  {
    path: PrivateRoutes.workCalendar,
    loadComponent: () =>
      import('@pages/work-calendar/work-calendar-page.component').then(
        (m) => m.WorkCalendarPageComponent,
      ),
    loadChildren: () =>
      import('@pages/work-calendar/work-calendar-page.routes').then(
        (m) => m.workCalendarPageRoutes,
      ),
    canActivate: [screenAccessGuard],
    data: { screen: PrivateRoutes.workCalendar },
  },
  {
    path: PrivateRoutes.holidays,
    loadComponent: () =>
      import('@pages/holiday/holiday-page.component').then(
        (m) => m.HolidayPageComponent,
      ),
    loadChildren: () =>
      import('@pages/holiday/holiday-page.routes').then(
        (m) => m.holidayPageRoutes,
      ),
    canActivate: [screenAccessGuard],
    data: { screen: PrivateRoutes.holidays },
  },

  // {
  //   path: 'scheduler',
  //   loadComponent: () => import('@pages/scheduler/scheduler-page.component').then(m => m.SchedulerPageComponent),
  //   loadChildren: () => import('@pages/scheduler/scheduler-page.routes').then(m => m.schedulerPageRoutes)
  // },
  {
    path: PrivateRoutes.projectsTasksGantt,
    loadComponent: () =>
      import(
        '@pages/projects-tasks-gantt/projects-tasks-gantt-page.component'
      ).then((m) => m.ProjectsTasksGanttPageComponent),
    loadChildren: () =>
      import(
        '@pages/projects-tasks-gantt/projects-tasks-gantt-page.routes'
      ).then((m) => m.projectsTasksGanttPageRoutes),
    canActivate: [screenAccessGuard],
    data: { screen: PrivateRoutes.projectsTasksGantt },
  },
  {
    path: PrivateRoutes.employeesTasksGantt,
    loadComponent: () =>
      import(
        '@pages/employees-tasks-gantt/employees-tasks-gantt-page.component'
      ).then((m) => m.EmployeesTasksGanttPageComponent),
    loadChildren: () =>
      import(
        '@pages/employees-tasks-gantt/employees-tasks-gantt-page.routes'
      ).then((m) => m.employeesTasksGanttPageRoutes),
    canActivate: [screenAccessGuard],
    data: { screen: PrivateRoutes.employeesTasksGantt },
  },
  {
    path: PrivateRoutes.personalEvents,
    loadComponent: () =>
      import('@pages/personal-events/personal-events-page.component').then(
        (m) => m.PersonalEventsPageComponent,
      ),
    loadChildren: () =>
      import('@pages/personal-events/personal-events-page.routes').then(
        (m) => m.personalEventsPageRoutes,
      ),
    canActivate: [screenAccessGuard],
    data: { screen: PrivateRoutes.personalEvents },
  },

  {
    path: PrivateRoutes.absence,
    loadComponent: () =>
      import('@pages/absence/absence-page.component').then(
        (m) => m.AbsencePageComponent,
      ),
    loadChildren: () =>
      import('@pages/absence/absence-page.routes').then(
        (m) => m.absencePageRoutes,
      ),
    canActivate: [screenAccessGuard],
    data: { screen: PrivateRoutes.absence },
  },

  {
    path: PrivateRoutes.employees,
    loadComponent: () =>
      import('@pages/employees/employees-page.component').then(
        (m) => m.EmployeesPageComponent,
      ),
    loadChildren: () =>
      import('@pages/employees/employees-page.routes').then(
        (m) => m.employeesPageRoutes,
      ),
    canActivate: [screenAccessGuard],
    data: { screen: PrivateRoutes.employees },
  },

  {
    path: PrivateRoutes.groupEvents,
    loadComponent: () =>
      import('@pages/group-events/group-events-page.component').then(
        (m) => m.GroupEventsPageComponent,
      ),
    loadChildren: () =>
      import('@pages/group-events/group-events-page.routes').then(
        (m) => m.groupEventsPageRoutes,
      ),
    canActivate: [screenAccessGuard],
    data: { screen: PrivateRoutes.groupEvents },
  },

  {
    path: PrivateRoutes.employeesSchedules,
    loadComponent: () =>
      import(
        '@pages/employees-schedule/employees-schedule-page.component'
      ).then((m) => m.EmployeesSchedulePageComponent),
    loadChildren: () =>
      import('@pages/employees-schedule/employees-schedule-page.routes').then(
        (m) => m.employeesSchedulePageRoutes,
      ),
    canActivate: [screenAccessGuard],
    data: { screen: PrivateRoutes.employeesSchedules },
  },

  {
    path: PrivateRoutes.projects,
    loadComponent: () =>
      import('@pages/projects/projects-page.component').then(
        (m) => m.ProjectsPageComponent,
      ),
    loadChildren: () =>
      import('@pages/projects/projects-page.routes').then(
        (m) => m.projectsPageRoutes,
      ),
    canActivate: [screenAccessGuard],
    data: { screen: PrivateRoutes.projects },
  },
  {
    path: PrivateRoutes.groups,
    loadComponent: () =>
      import('@pages/groups/groups-page.component').then(
        (m) => m.GroupsPageComponent,
      ),
    loadChildren: () =>
      import('@pages/groups/groups-page.routes').then((m) => m.groupsRoutes),
    canActivate: [screenAccessGuard],
    data: { screen: PrivateRoutes.groups },
  },
  {
    path: PrivateRoutes.entityLog,
    loadComponent: () =>
      import('@pages/entity-log/entity-log-page.component').then(
        (m) => m.EntityLogPageComponent,
      ),
    loadChildren: () =>
      import('@pages/entity-log/entity-log-page.routes').then(
        (m) => m.entityLogRoutes,
      ),
    canActivate: [screenAccessGuard],
    data: { screen: PrivateRoutes.entityLog },
  },
];
