import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {
  BadRequestErrorResponce,
  NotFoundErrorResponce,
  StatusCode,
} from '@models/error-api';
import { errorMessages } from './error-messages';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly _toaster: ToastrService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((httpError: HttpErrorResponse) => {
        let errorMessage = '';
        let errorCode = '';
        switch (httpError.status) {
          case StatusCode.BadRequest: {
            const badRequestError = httpError.error as BadRequestErrorResponce;
            errorCode = badRequestError.code;
            if (badRequestError.detail === 'bindException') {
              badRequestError.errors.forEach((err) => {
                errorMessage =
                  errorMessage + `${err.propertyPath} ${err.message}\n`;
              });
            } else {
              let messageFromMap = errorMessages.get(badRequestError.title);
              errorMessage = messageFromMap ? messageFromMap : badRequestError.title;
            }
            break;
          }

          case StatusCode.Unauthorized: {
            errorMessage = 'Необходимо авторизоваться';
            break;
          }
          case StatusCode.NotFound: {
            const notFoundError = httpError.error as NotFoundErrorResponce;
            errorMessage = notFoundError.title;
            break;
          }

          case StatusCode.Forbidden: {
            errorMessage = 'У вас нет прав для выполнения этого действия';
            break;
          }

          case StatusCode.InternalServerError: {
            errorMessage = 'На сервере произошла ошибка';
            break;
          }

          default:
            errorMessage = 'Произошла неизвестная ошибка';
        }

        if (errorCode && 'entity.patchNoChanges' === errorCode) {
          this._toaster.info('Все изменения сохранены');
        } else {
          this._toaster.error(errorMessage);
        }
        return throwError(() => httpError);
      }),
    );
  }
}
