export enum UserRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export interface UserDto {
  id: string;
  displayName?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  avatarUri?: string;
  hasAvatar?: boolean;
}
