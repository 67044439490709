export enum StatusCode {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
}

export interface ErrorResponce {
  type: string;
  title: string;
  status: StatusCode;
  detail: string;
  instance: string;
  requestId: string;
  timestamp: string;
  code: string;
}

export interface NotFoundErrorResponce extends ErrorResponce {
  code: string;
  properties: Record<string, any>;
}

export interface BadRequestErrorResponce extends ErrorResponce {
  errors: ValidationErrorDetails[];
}

export interface ValidationErrorDetails {
  message: string;
  propertyPath: string;
}
