import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { AuthApiService } from './api/screen.api.service';
import { BehaviorSubject, tap } from 'rxjs';
import { UserRole } from '@models/user';

@Injectable({
  providedIn: 'root',
})
export class ScreenService {
  private availableScreensSubject: BehaviorSubject<string[]> =
    new BehaviorSubject<string[]>([]);
  public availableScreens$ = this.availableScreensSubject.asObservable();

  constructor(
    private readonly keycloak: KeycloakService,
    private readonly authApiService: AuthApiService,
  ) {}

  public loadAvailableScreens(): void {
    this.authApiService
      .getAvailableScreens()
      .pipe(
        tap((screens) => {
          this.availableScreensSubject.next(screens);
        }),
      )
      .subscribe();
  }

  public checkScreenAvailable(screen: string): boolean {
    const isAdmin = this.keycloak.getUserRoles().includes(UserRole.ADMIN);

    return this.availableScreensSubject.value.includes(screen) || isAdmin;
  }
}
