import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { NavItem } from '@models/menu';
import { PrivateRoutes } from '@models/routes';
import { ScreenService } from '@services/screen.service';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrl: './nav-menu.component.scss',
  standalone: true,
  imports: [
    RouterModule,
    MatSidenavModule,
    MatIconButton,
    MatIcon,
    MatToolbarModule,
    MatListModule,
  ],
})
export class NavMenuComponent implements OnInit {
  @ViewChild(MatSidenav, { static: true }) sidenav!: MatSidenav;

  private readonly separator: string = '/';

  activeNavItem: NavItem | null = null;
  keepSidenav = false;

  navMenuItems: NavItem[] = [
    { value: 'Производственный календарь', url: PrivateRoutes.workCalendar },
    { value: 'Персональный календарь', url: PrivateRoutes.personalCalendar },
    { value: 'Праздники', url: PrivateRoutes.holidays },
    { value: 'Персональные события', url: PrivateRoutes.personalEvents },
    { value: 'Отпуска и больничные', url: PrivateRoutes.absence },
    { value: 'Групповые события', url: PrivateRoutes.groupEvents },
    {
      value: 'Задачи в разрезе проектов',
      url: PrivateRoutes.projectsTasksGantt,
    },
    {
      value: 'Задачи в разрезе ресурсов',
      url: PrivateRoutes.employeesTasksGantt,
    },
    { value: 'Сотрудники', url: PrivateRoutes.employees },
    { value: 'Графики сотрудников', url: PrivateRoutes.employeesSchedules },
    { value: 'Проекты', url: PrivateRoutes.projects },
    { value: 'Группы', url: PrivateRoutes.groups },
    { value: 'История изменений объектов', url: PrivateRoutes.entityLog },
  ];
  displayedNavItems: NavItem[] = this.navMenuItems;

  constructor(
    private readonly _router: Router,
    private readonly _screenService: ScreenService,
    private readonly _authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.setSidenav();
    this.listenAvailableScreens();
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentNavMenuItem: NavItem | undefined =
          this.displayedNavItems.find(
            (item: NavItem) => item.url === event.url.split(this.separator)[1],
          );

        this.setActiveNavItem(currentNavMenuItem ?? null);
      }
    });
  }

  setSidenav(): void {
    this.sidenav.open();
    const savedKeepSidenav = localStorage.getItem('keepSidenav');
    this.keepSidenav = savedKeepSidenav === 'true';
  }

  setActiveNavItem(navItem: NavItem | null): void {
    this.activeNavItem = navItem;
  }

  onSidenavClick(navItem: NavItem | null): void {
    this.setActiveNavItem(navItem);
    if (!this.keepSidenav) {
      this.sidenav.close();
    }
  }

  toggleKeepSidenav(): void {
    this.keepSidenav = !this.keepSidenav;
    localStorage.setItem('keepSidenav', String(this.keepSidenav));
  }

  logout(): void {
    this._authService.logout();
  }

  listenAvailableScreens(): void {
    this._screenService.availableScreens$.subscribe(() => {
      this.displayedNavItems = this.navMenuItems.filter((navItem) =>
        this._screenService.checkScreenAvailable(navItem.url),
      );
    });
  }
}
