import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { LayoutComponent } from './layout/layout/layout.component';
import { AuthService } from './services';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from './utils/cutom-paginator';
import { ScreenService } from '@services/screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterOutlet, LayoutComponent],
  providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginator }],
})
export class AppComponent {
  constructor(
    private authService: AuthService,
    private screenService: ScreenService,
  ) {
    this.authService.login().then(() => {
      this.screenService.loadAvailableScreens();
    });
  }
}
