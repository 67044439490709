<div class="container">
  <mat-toolbar color="primary" class="toolbar">
    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon>{{
        sidenav.opened ? 'keyboard_arrow_left' : 'keyboard_arrow_right'
      }}</mat-icon>
    </button>
    <h1 class="app-name">{{ activeNavItem?.value }}</h1>
  </mat-toolbar>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #snav mode="side">
      <header class="sidenav-header">
        <button
          mat-icon-button
          class="keep-sidenav-button"
          [class.active]="keepSidenav"
          (click)="toggleKeepSidenav()"
        >
          <mat-icon>push_pin</mat-icon>
        </button>
      </header>
      <mat-nav-list>
        @for (item of displayedNavItems; track item.url) {
          <a
            (click)="onSidenavClick(item)"
            mat-list-item
            class="menu-item"
            cdkMenuItem
            [routerLink]="item.url"
          >
            {{ item.value }}
          </a>
        }
      </mat-nav-list>
      <footer class="sidenav-footer">
        <button mat-icon-button class="logout-button" (click)="logout()">
          <mat-icon>logout</mat-icon>
        </button>
      </footer>
    </mat-sidenav>

    <mat-sidenav-content>
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
